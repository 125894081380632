<template>
  <div>
    <v-banner
      id='commissionBlockerBanner'
      :sticky='false'
      color='info'
      class='mb-4'
      style='z-index:20'
      v-if='commissionSetup.plans_saving && commissionSetup.plans_saving.status'
    >

      <span class='white--text'>
        Commission plans have been updated. Please wait while we apply them to your unfinalized commissions. <br/><br/>You can navigate away from this page and it will continue to process in the background.
        <br /><br />

        <small>
          <v-progress-circular :size='14' indeterminate></v-progress-circular>
          Updated {{ commissionSetup.plans_saving.progress.percent.toFixed(0) }}% of unfinalized commissions</small>
        </span>
      <br /><br />
      <v-progress-linear
        :value='commissionSetup.plans_saving.progress.percent'
        buffer-value='100'
        color='light-blue'
        height='10'
        striped
      ></v-progress-linear>
    </v-banner>

    <div>
      <div id='commissionBlockerDiv'
           v-if='commissionSetup.plans_saving && commissionSetup.plans_saving.status'></div>
      <div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      processingInterval: null,
    }
  },
  methods:{
    ...mapActions({
      loadCommissionSetup: 'commissions/loadCommissionSetup',
    })
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
    }),
  },
  watch: {
    commissionSetup: {
      handler() {
        if (this.commissionSetup.plans_saving && this.commissionSetup.plans_saving.status) {
          clearInterval(this.processingInterval)
          this.processingInterval = setInterval(() => {
            this.loadCommissionSetup();
          }, 5000)
        } else {
          clearInterval(this.processingInterval)
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang='scss' scoped>
#commissionBlockerDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 19;
}

#commissionBlockerBanner {
  ::v-deep.v-icon {
    color: #fff !important;
  }
}
</style>
